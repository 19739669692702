import * as React from "react";
import type { SVGProps } from "react";
const SvgReplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.5 19.86A8.25 8.25 0 0 0 20.25 12M3.25 5.5v4.25H7.5"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 3.75c4.56 0 8.25 3.69 8.25 8.25s-3.69 8.25-8.25 8.25S3.75 16.56 3.75 12M4.14 9.5A8.25 8.25 0 0 1 12 3.75"
    />
  </svg>
);
export default SvgReplay;
