import * as React from "react";
import type { SVGProps } from "react";
const SvgMemberProduct = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m3.75 9.75 1.5-6h13.5l1.5 6v7.5a3 3 0 0 1-3 3H3.75z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M20.25 9.75H3.75M12 4v6"
    />
    <path
      fill="currentColor"
      d="M9.26 14.694c-.64-.474-.154-1.414.02-1.694-.314.347-.628.687-.868 1.06-.38.587-.546 1.234-.286 1.647.466.747 1.58.4 2.287.1L17 13.027l-6.507 1.72c-.547.14-.987.127-1.234-.053"
    />
  </svg>
);
export default SvgMemberProduct;
