import * as React from "react";
import type { SVGProps } from "react";
const SvgGridView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M13.5 19.5h6v-6h-6zM4.5 19.5h6v-6h-6zM13.5 10.5h6v-6h-6zM4.5 10.5h6v-6h-6z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGridView;
