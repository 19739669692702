import * as React from "react";
import type { SVGProps } from "react";
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3a4.5 4.5 0 0 0-4.5 4.5H9a3 3 0 0 1 3-3zM7.5 7.5A4.5 4.5 0 0 0 12 12v-1.5a3 3 0 0 1-3-3zM12 12a4.5 4.5 0 0 0 4.5-4.5H15a3 3 0 0 1-3 3zm4.5-4.5A4.5 4.5 0 0 0 12 3v1.5a3 3 0 0 1 3 3zM4.5 21v-3H3v3zm0-3a3 3 0 0 1 3-3v-1.5A4.5 4.5 0 0 0 3 18zm3-3h9v-1.5h-9zm9 0a3 3 0 0 1 3 3H21a4.5 4.5 0 0 0-4.5-4.5zm3 3v3H21v-3z"
    />
  </svg>
);
export default SvgProfile;
