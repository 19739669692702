import * as React from "react";
import type { SVGProps } from "react";
const SvgVideoPlayer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.39 11.35 10.28 8.4a.75.75 0 0 0-1.126.649v5.903a.75.75 0 0 0 1.125.649l5.111-2.951a.75.75 0 0 0 0-1.3Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M19.5 18.75a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVideoPlayer;
