import * as React from "react";
import type { SVGProps } from "react";
const SvgRewards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m18.75 14.8 2.8-2.8-2.8-2.8V5.25H14.8L12 2.45l-2.8 2.8H5.25V9.2L2.45 12l2.8 2.8v3.95H9.2l2.8 2.8 2.8-2.8h3.95z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m15.45 9.35-4.77 4.77-2.65-2.65"
    />
  </svg>
);
export default SvgRewards;
