import * as React from "react";
import type { SVGProps } from "react";
const SvgMeasure = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.75 10.5v6.75a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5V10.5M5.558 8.25h12.885"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m15.79 5.599 2.653 2.65-2.653 2.653M8.21 5.599l-2.652 2.65 2.652 2.653M17.25 19v-2.5M12 19v-2.5M6.75 19v-2.5"
    />
  </svg>
);
export default SvgMeasure;
