import * as React from "react";
import type { SVGProps } from "react";
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.5 4.98a6.75 6.75 0 0 0-4.25 6.27v1.5c0 .83-.67 1.5-1.5 1.5v3h16.5v-3c-.83 0-1.5-.67-1.5-1.5v-1.5c0-3.43-2.56-6.27-5.88-6.69-.5-.07-.87-.49-.87-.99V1.5M14.25 19.5c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25"
    />
  </svg>
);
export default SvgNotification;
