import * as React from "react";
import type { SVGProps } from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.75 13v6A2.25 2.25 0 0 0 6 21.25h12A2.25 2.25 0 0 0 20.25 19v-6"
    />
    <path stroke="currentColor" strokeWidth={1.552} d="M12 19V4" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.774 8.834 12 4.061 7.227 8.834"
    />
  </svg>
);
export default SvgShare;
