import * as React from "react";
import type { SVGProps } from "react";
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.25 20.25H6A2.25 2.25 0 0 1 3.75 18v-5.25h5.5zM18 20.25h-3.25V3.75h5.5V18A2.25 2.25 0 0 1 18 20.25ZM9.25 20.25h5.5v-12h-5.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAnalytics;
