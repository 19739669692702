import * as React from "react";
import type { SVGProps } from "react";
const SvgTrendingDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M15 8.25h5.25V3M20.25 8.25 14 2.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.75 20.25h5.5v-4.5h-5.5zM6 20.25h3.25V3.75h-5.5V18c0 1.24 1.01 2.25 2.25 2.25ZM9.25 20.25h5.5v-9h-5.5z"
    />
  </svg>
);
export default SvgTrendingDown;
