import * as React from "react";
import type { SVGProps } from "react";
const SvgTrendingUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.25 9V3.75H4M9.25 3.75 3 9.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.25 15.75h-5.5v4.49h5.5zM18 20.25h-3.25V3.75h5.5V18c0 1.24-1.01 2.25-2.25 2.25ZM14.75 11.25h-5.5v9h5.5z"
    />
  </svg>
);
export default SvgTrendingUp;
