import * as React from "react";
import type { SVGProps } from "react";
const SvgShow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.602 11.566a.74.74 0 0 1 0 .867C20.54 13.923 16.73 18.75 12 18.75s-8.532-4.828-9.593-6.318a.74.74 0 0 1 0-.865C3.468 10.077 7.27 5.25 12 5.25s8.54 4.826 9.602 6.316Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShow;
