import * as React from "react";
import type { SVGProps } from "react";
const SvgReceiptNone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.355 22.476 2.995 5.116l1.013-1.014 17.36 17.36z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 3H7.5v2.48L9 6.98V4.5h10.5v12.98l1.376 1.375c.08-.27.124-.558.124-.855zm-.93 17.17-1.062-1.061c-.267.243-.62.391-1.008.391H8.597c.256-.442.403-.954.403-1.5V9.1L7.5 7.6V18c0 .826-.674 1.5-1.5 1.5s-1.5-.674-1.5-1.5v-4.5H6V12H3v6c0 1.654 1.346 3 3 3h12a3 3 0 0 0 2.07-.83M11.9 12h-1.4v.5l1 1h1.9zm3.62 1.5h.98V12h-2.48zm-3-3H18V9h-6.98zm-2.02-3H18V6h-7.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReceiptNone;
