import * as React from "react";
import type { SVGProps } from "react";
const SvgFirstAccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.547}
      d="M10.696 14.886a5.8 5.8 0 0 1-2.09.095 5.68 5.68 0 0 1-4.807-4.841 5.648 5.648 0 0 1 6.333-6.342 5.674 5.674 0 0 1 4.85 4.815 5.64 5.64 0 0 1-1.466 4.633 2.144 2.144 0 0 0 .095 2.967l.99.99h1.735v1.734l1.301 1.302h2.603v-2.603l-4.286-4.286"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={1.5}
      d="M8.75 10.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z"
    />
  </svg>
);
export default SvgFirstAccess;
