import * as React from "react";
import type { SVGProps } from "react";
const SvgGestureTap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.396 8.25a4.5 4.5 0 1 1 7.598-1.5"
      opacity={0.2}
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 13.5v-2.25a1.5 1.5 0 0 0-3 0M14.25 13.5V9.75a1.5 1.5 0 0 0-3 0"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 13a1.5 1.5 0 0 1 3 0v2a6.73 6.73 0 0 1-1.977 4.773A6.73 6.73 0 0 1 13.5 21.75a6.73 6.73 0 0 1-4.773-1.977l-6.059-6.059a1.501 1.501 0 0 1 2.122-2.121l2.18 2.18a.75.75 0 0 0 1.28-.531V5.25a1.5 1.5 0 1 1 3 0v8.25"
    />
  </svg>
);
export default SvgGestureTap;
